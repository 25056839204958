import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// Module import
import { OrderList } from '@modules/OrderList';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Button } from '@components/Button';
import { SatisfactionSurvey } from '@components/SatisfactionSurvey';
import { Modal, IModalRef } from '@components/Modal';
import { SomaBoxAlert } from '@components/FeatureAlert/SomaBox';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Store import
import { INewFormatOrder } from '@store/modules/auth/types';

// Warning import
import { MigrationWarning } from './MigrationWarning';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@dashboard/INDEX';

const Dashboard: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const history = useHistory();

  // Global states
  const user = useSelector(state => state.auth);

  // Local states
  const [orders, setOrders] = useState<INewFormatOrder[]>([]);
  const [loading, setLoading] = useState(false);

  // Local states
  const [greeting] = useState(() => {
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 12 && hour <= 17) return t('GOOD_AFTERNOON', 'Boa tarde');
    if (hour >= 17 && hour <= 24) return t('GOOD_EVENING', 'Boa noite');
    return t('GOOD_MORNING', 'Bom dia');
  });

  // Local refs
  const somaBoxReleaseModalRef = useRef<IModalRef>(null);

  const userIsManager = user.profile_type === 'store';

  // Get orders
  const getOrders = useCallback(
    async (page = 1) => {
      setLoading(true);

      try {
        // API call
        const response = await api.get('/v1/order', {
          params: {
            page,
          },
        });

        // Validation
        if (!Array.isArray(response.data.data)) throw new Error();

        // Result
        const new_orders = [...orders, ...response.data.data];

        // Iterate to remove repeated items
        const result = new_orders.reduce<INewFormatOrder[]>(
          (total, current) => {
            if (!total.some(order => order.id === current.id))
              total.push(current);
            return total;
          },
          [],
        );

        setOrders(result);

        setLoading(false);
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);

        setLoading(false);

        throw err;
      }
    },
    [orders, t],
  );

  const START_DATE = new Date('2024-12-31T00:00:00');
  const END_DATE = new Date('2025-01-05T23:59:59');

  const shouldShowMigrationWarning = () => {
    const now = new Date();

    const isInPeriod = now >= START_DATE && now <= END_DATE;
    return isInPeriod;
  };

  // Initial load
  useEffect(() => {
    sessionStorage.removeItem('savedOrderCode');
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const somaBoxReleaseModal = localStorage.getItem('somaBoxReleaseModal');

    const today = new Date().toISOString().slice(0, 10);

    const modalHasBeenSeenToday = somaBoxReleaseModal === today;

    const NVisEnabled = user?.store?.brands.some(
      brand =>
        brand.brand.enabled && Number(brand.brand.code) === 16 && brand.enabled,
    );

    if (NVisEnabled) {
      return;
    }

    if (!modalHasBeenSeenToday) {
      somaBoxReleaseModalRef.current?.show();
      localStorage.setItem('somaBoxReleaseModal', today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userName = useMemo(() => {
    if (!user.name) return '';
    const name = user.name?.split(' ')[0]?.toLowerCase();
    return `${name[0]?.toUpperCase()}${name.slice(1)}`;
  }, [user.name]);

  const modals = useMemo(
    () => (
      <>
        <Modal ref={somaBoxReleaseModalRef} size="small-1">
          <SomaBoxAlert
            hide={() => somaBoxReleaseModalRef.current?.hide()}
            userIsManager={userIsManager}
          />
        </Modal>
      </>
    ),
    [userIsManager],
  );

  return (
    <Container>
      {modals}
      <div className="headerContainer">
        {shouldShowMigrationWarning() && <MigrationWarning />}
        <h2>
          {greeting}
          {!!userName && `, ${userName}`} !
        </h2>
      </div>
      <section className="orders">
        <h2>{t('LAST_ORDERS', 'Últimos pedidos realizados')}</h2>
        <div className="list orders">
          {loading ? (
            <LoadingIndicator />
          ) : (
            <OrderList orders={Array.from(orders).slice(0, 4)} />
          )}
        </div>
      </section>
      <section className="actions">
        <Button
          onClick={() => {
            history.push('/order/new?addProduct=true');
          }}
          color="success"
          noFullWidth
        >
          {t('START_ORDER', 'CONSULTAR PRODUTO')}
        </Button>
      </section>
      <SatisfactionSurvey />
    </Container>
  );
};

export { Dashboard };
